<template>
    <div class="relative" v-if="showMap">
              <gmap-map
              ref="gmap"
                :center="center"
                :zoom="11"
                style="width: 100%; height: 665px">
                <gmap-marker :position="center" :clickable="false"></gmap-marker>
                <gmap-custom-marker v-for="(marker, index) in userLocation" :key="index"
                  :marker="{latitude: marker.latitude, longitude: marker.longitude, mainId: marker.mainId, object: marker}">
                    <div @click="showDetails(marker)" :class="`staff-popup card rounded ${marker.type === 'request' ? 'bg-request' : marker.type === 'task' ? 'bg-task' : 'bg-primary'} `">
                      <div class="chip_parent_box w-full" style="padding: 4px 0px 4px 0px;">
                        <p v-if="!marker.isrequest && !marker.isTasK" class="text-white heading-6">Job #{{marker.mainId}}</p>
                        <p v-if="marker.isrequest && !marker.isTasK" class="text-white heading-6">Request #{{marker.mainId}}</p>
                        <p v-if="!marker.isrequest && marker.isTasK" class="text-white heading-6">Task #{{marker.mainId}}</p>
                        <p class="text-white px-4">
                            <span v-if="marker.start !== ''">{{marker.start | filterForLocationTime}}</span>
                            <span v-if="marker.end !== ''">-{{marker.end | leaveTimeFilterForActivity}}</span>
                            <span v-if="marker.start === '' && marker.end === ''" class="overFlowParaA">
                              <span v-if="marker.title !== '' && !marker.isTasK">{{marker.title}}</span>
                              <span v-if="marker.title === '' && !marker.isTasK">Request for {{marker.customerName}}</span>
                            </span>
                        </p>
                        <div class="chip_tooltiptext whitespace-pre-line card rounded-xl bg-white text-left p-2 heading-4">
                          <div class=" heading-5 text-blue1" v-if="!marker.isTasK && !marker.isrequest">
                            <router-link :to="{ name: 'jobDetail', params: { jobId: marker.mainId}} "  class="cursor-pointer">Job #{{marker.mainId}}</router-link>
                            <!-- <div class="cursor-pointer" @click.native="clickonmap1">Job #{{marker.mainId}}</div> -->
                          </div>
                          {{cardTititle}}
                          <div class=" heading-5 text-blue1" v-if="!marker.isTasK && marker.isrequest">
                            <router-link :to="{ name: 'requestDetail', params: { requestId: marker.mainId}} "  class="cursor-pointer">Request #{{marker.mainId}}</router-link>
                            <!-- <div class="cursor-pointer">Request #{{marker.mainId}}</div> -->
                          </div>
                          <div class="heading-5 text-blue1" v-if="marker.isTasK">
                            <router-link :to="{ name: 'TaskDetail', params: { taskId: marker.mainId}, query: {date: marker.start}} "  class="cursor-pointer">Task #{{marker.mainId}}</router-link>
                            <!-- <div class="cursor-pointer">Task #{{marker.mainId}}</div> -->
                          </div>
                          <div class="text-text2 heading-6">
                            <p class="mb-1 " v-if="marker.title !== ''" >
                             <span class=" font-semibold heading-7"> Title: </span>
                              {{marker.title}}</p>
                            <p class="mb-1 " v-if="marker.customerName !== ''" >
                              <span class=" font-semibold heading-7"> Customer: </span>
                              {{marker.customerName}}</p>
                              <p class="mb-1 " v-if="marker.start !== ''">
                                <span>
                                  <span class=" font-semibold heading-7"> Schedule: </span>
                                  {{marker.start | filterForLocationTime}}
                                </span>
                                <span v-if="marker.end !== ''">-{{marker.end | leaveTimeFilterForActivity}}</span>
                                <span v-if="marker.start === '' && marker.end === ''" class="overFlowParaA">{{marker.title}}</span>
                              </p>
                              <p class="mb-1 " v-if="marker.addressLine1 !== ''" >
                               <span class=" font-semibold heading-7"> Address: </span>
                               <span>{{marker.addressLine1 !== '' ? marker.addressLine1 : '' }}</span>
                               <span>{{marker.city !== undefined ? ', '+ marker.city  : '' }}</span>
                               <span>{{marker.state !== undefined ? ', '+ marker.state  : '' }}</span>
                               <span>{{marker.zip !== undefined ? ', '+ marker.zip : '' }}</span>
                              </p>
                              <p class="mb-1 " v-if="marker.assignToList !== ''" >
                                <span class=" font-semibold heading-7">Assign to:</span>
                                  {{marker.assignToList}}</p>
                              <p class="mb-1 " v-if="marker.visitId > 0" >
                                <span class=" font-semibold heading-7">Visit id:</span>
                                  {{marker.visitId}}</p>
                              <p class="mb-1 " v-if="marker.totalJobVisit > 0" >
                                <span class=" font-semibold heading-7">Total Visit:</span>
                                  {{marker.totalJobVisit}}</p>
                              <p class="mb-1 " v-if="marker.summary !== ''" >
                               <span class=" font-semibold heading-7"> Summary: </span>
                                {{marker.summary}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </gmap-custom-marker>
                  <gmap-custom-marker v-for="(markerA, indexA) in employeeLocation" :key="indexA + `A`"
                  :marker="{latitude: markerA.locationObj.latitude, longitude: markerA.locationObj.longitude}">
                    <div class=" staff-popupA">
                      <div  class="text-blue1 heading-2 icon_parent_box">
                      <span>
                        <i style="height:45px;width:40px;" class="fa-solid fa-location-pin"></i>
                      </span>
                      <div style="width: 24px;height: 24px;" class="absolute top-0 flex items-center justify-center bg-white rounded-full short_name font-bold  ">
                        <span style="text-transform: uppercase;">{{markerA.firstName.charAt(0)}}{{markerA.lastName.charAt(0)}}</span>
                      </div>

                      <div class="tooltiptext whitespace-pre-line text-primary bg-white rounded  p-2 heading-4 ">
                        <p class="text-primary whitespace-nowrap">{{markerA.firstName}} {{markerA.lastName}}</p>
                        <p v-if="markerA.addedDate !== ''" class="text-primary whitespace-nowrap">
                          {{markerA.addedDate | filterForLocationTime}}
                        </p>
                      </div>
                    </div>
                    </div>
                  </gmap-custom-marker>
              </gmap-map>
            </div>
</template>
<script>
import {FilterPermissions} from '@/utils/Permissions.js'
import store from '@/store'
import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import GmapCustomMarker from '../../setting/location/vue2-gmap-custom-marker/gmap-custom-marker.vue'
Vue.use(VueGoogleMaps, {
  load: {
    key: store.getters.googleMapApiKey,
    libraries: 'places,drawing' // necessary for places input
  }
})
export default {
  props: ['scheduledList', 'lastEmpData', 'isUnSchedule', 'unscheduleventList'],
  name: 'GoogleMap',
  components: {
    'gmap-custom-marker': GmapCustomMarker,
  },
  data () {
    return {
        cardTititle: "",
        scheduleData: [],
        employeeLocation: [],
        userLocation: [],
        selectedEmpId: [],
        center: null,
        places: [],
        showMap: false,
        currentPlace: null
    }
  },
   created() {

  },
  mounted () {
    
    this.showMap = true
    this.getEmployeeLocation()
    this.getLocationdetail()
    let orgDetail = JSON.parse(localStorage.getItem('orgInfo'))
    this.center = { lat: orgDetail.latitude, lng: orgDetail.longitude}
  },
  watch: {
    unscheduleventList: {
      handler () {
        //console.log("check 2222", this.unscheduleventList);
        //console.log("scheduledList 22222", this.scheduledList);
        this.showMap = true
        this.scheduleData = []
        this.scheduleData = this.scheduledList.concat(this.unscheduleventList)
        this.getLocationdetail()
      },
      deep: true
    },
    scheduledList: {
      handler () {
        // console.log("check 111111111111111", this.unscheduleventList);
        // console.log("scheduledList 111111111111111", this.scheduledList);
        this.showMap = true
        this.scheduleData = []
        this.scheduleData = this.scheduledList.concat(this.unscheduleventList)
        this.getLocationdetail()
      },
      deep: true
    },
    lastEmpData: {
      handler () {
        this.showMap = true
        this.getEmployeeLocation()
      },
      deep: true
    },
  },
  methods: {
    showDetails (data) {
      console.log(data);
      this.cardTititle = 'rrrrrrrrrrrrrrrrrrrrrrrr'
    },
    clickonmap1 (data) {
      let url = ''
      if (!data.object.isTasK && !data.object.isrequest) {
        let temp = FilterPermissions('job')
        if (temp) {
          url = this.$router.resolve({name: 'jobDetail', params: { jobId: data.object.mainId}})
          window.open(url.href, '_blank')
        } else {
          this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to open Job detail`})
        }
      } else if (!data.object.isTasK && data.object.isrequest) {
        let temp = FilterPermissions('request')
        if (temp) {
          url = this.$router.resolve({name: 'requestDetail', params: { requestId: data.object.mainId}})
          window.open(url.href, '_blank')
        } else {
          this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to open Request detail`})
        }
      } 
      if (data.object.isTasK) {
        let temp = FilterPermissions('task')
        if (temp) {
          url = this.$router.resolve({name: 'TaskDetail', params: { taskId: data.object.mainId}, query: {date: data.object.start}})
          window.open(url.href, '_blank')
        } else {
          this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to open Task detail`})
        }
      }
    },
    discard () {
      this.$root.$emit('locationViewHandler', false)
    },
    getEmployeeLocation(){
      this.showMap = true
      this.employeeLocation = []
      this.lastEmpData.map((data) => {
        let dataObj = {
          addedDate: data.addedDate,
          firstName: data.firstName,
          lastName: data.lastName,
          locationObj: JSON.parse(data.locationObj),
          userDetailId: data.userDetailId
        }
        this.employeeLocation.push(dataObj)
      })

      let orgDetail = JSON.parse(localStorage.getItem('orgInfo'))
      this.center = { lat: orgDetail.latitude, lng: orgDetail.longitude}
        setTimeout(() => {
        this.setBound()
      }, 100)
    },
    getLocationdetail () {
      //console.log('check ------------==+++++++++++++++++++++++++++++', this.scheduleData);
      //console.log('isUnSchedule', this.isUnSchedule)
      if (!this.isUnSchedule) {
        let eventsArray = []
        // for (let pIndex = 0; pIndex < this.scheduleData.length; pIndex++) {
          // const parentObj = this.scheduleData[pIndex]
          for (let cIndex = 0; cIndex < this.scheduleData.length; cIndex++) {
            const Object = this.scheduleData[cIndex]
            if ((Object.entityType === 'job' || Object.entityType === 'request') && Object.latitude !== 0 && Object.longitude !== 0) {
              //console.log('Object', Object)
              let data = {
                latitude: Object.latitude,
                longitude: Object.longitude,
                mainId: Object.jobId,
                type: Object.entityType,
                isrequest: Object.isrequest,
                isTasK: false,
                start: Object.start,
                end: Object.end,
                title: Object.title,
                customerName: Object.customerName,
                addressLine1: Object.addressLine1,
                assignToList: Object.assignToList,
                visitId: Object.visitId,
                totalJobVisit: Object.totalJobVisit,
                summary: Object.summary,
              }
              eventsArray.push(data)
            } else if (Object.entityType === 'task' && Object.latitude !== 0 && Object.longitude !== 0) {
              //console.log('Object1231', Object)
              let data = {
                latitude: Object.latitude,
                longitude: Object.longitude,
                mainId: Object.taskId,
                type: Object.entityType,
                isrequest: false,
                isTasK: true,
                start: Object.startTime,
                end: Object.endTime,
                title: Object.taskTitle,
                customerName: '',
                addressLine1: '',
                assignToList: '',
                visitId: '',
                totalJobVisit: '',
                summary: '',
              }
              eventsArray.push(data)
            }
            
          }
        // }
        // const array = eventsArray
        //console.log(' eventsArray^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^',  eventsArray)
        // const key = 'jobId'
        // const arrayUniqueByKey = [...new Map(array.map(item =>[item[key], item])).values()]
        this.userLocation = eventsArray
      } else {
        for (let pIndex = 0; pIndex < this.scheduleData.length; pIndex++) {
          const Object = this.scheduleData[pIndex]
          //// console.log('Object', Object)
          let data = {
            latitude: Object.latitude,
            longitude: Object.longitude,
            mainId: Object.jobId,
            type: Object.entityType,
            isrequest: Object.isrequest,
            isTasK: false,
            start: Object.start,
            end: Object.end,
            title: Object.title,
            customerName: Object.customerName,
            addressLine1: Object.addressLine1,
            assignToList: Object.assignToList,
            visitId: Object.visitId,
            totalJobVisit: Object.totalJobVisit,
            summary: Object.summary,
          }
          this.userLocation.push(data)
        }
      }
      this.showMap = true
      let orgDetail = JSON.parse(localStorage.getItem('orgInfo'))
      this.center = { lat: orgDetail.latitude, lng: orgDetail.longitude}
        setTimeout(() => {
        this.setBound()
      }, 100)
      //console.log(' this.userLocation^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^',  this.userLocation)
    },
    clickonmap (data) {
      this.$store.dispatch('SetLoader', {status: true, message: 'Redirecting...'})
      setTimeout(() => {
        this.$store.dispatch('SetLoader', {status: false, message: ''})
        let routeData1 = this.$router.resolve({name: 'jobDetail', params: {jobId: data.jobId}})
        window.open(routeData1.href, '_blank')
      }, 200);
    },
    setBound () {
      //// console.log('this.userLocation 11111', this.userLocation)
      this.$refs.gmap.$mapPromise.then((map) => {
        let fitBoud = false
        const bounds = new window.google.maps.LatLngBounds()
        bounds.extend(this.center)
        for (let m of this.userLocation) {
          if (parseFloat(m.latitude) > 0) {
            fitBoud = true
            let position = {lat: parseFloat(m.latitude), lng: parseFloat(m.longitude)}
            bounds.extend(position)
          }
        }
        for (let m of this.employeeLocation) {
          if (parseFloat(m.locationObj.latitude) > 0) {
            fitBoud = true
            let position = {lat: parseFloat(m.locationObj.latitude), lng: parseFloat(m.locationObj.longitude)}
            bounds.extend(position)
          }
        }
        if (fitBoud === true) {
          map.fitBounds(bounds)
        }
      })
    },
  },
  beforeDestroy () {}
}
</script>
<style scoped>
.imageFullScreen {
  width: auto;
  max-height: 100%;
  justify-content: center;
  align-items: center;
}

.staff-popupA {
  display: flex;
  justify-content: center;
    -webkit-transition: none!important;
    transition: none!important;
    color: #000;
    border-color: #24242400;
    width: fit-content;
    min-width: 38px;
    border-radius: 12px;
    position: absolute;
    border-width: 2px;
    border-style: solid;
    height: auto;
    -webkit-transition: .3s all cubic-bezier(.39,.575,.565,1);
    transition: .3s all cubic-bezier(.39,.575,.565,1);
    font-size: 13px;
    text-align: center;
       left: 0px;
    top: -42px;
}
.staff-popup {
    -webkit-transition: none!important;
    transition: none!important;
    color: #000;
    /* background-color: #ffffff; */
    border-color: #626f80;
    width: 250px;
    min-width: 38px;
    border-radius: 12px;
    position: absolute;
    border-width: 2px;
    border-style: solid;
    height: 43px;
    -webkit-transition: .3s all cubic-bezier(.39,.575,.565,1);
    transition: .3s all cubic-bezier(.39,.575,.565,1);
    font-size: 13px;
    text-align: center;
    left: -103px;
    top: -45px;
}
.staff-popup img {
    height: 35px;
    border-radius: 50%;
}
.staff-popupA:before {
  background-color: inherit;
  border-color: inherit;
  display: inline-block;
  margin: 0;
  content: '';
  width: 10px;
  height: 10px;
  position: absolute;
  top: 36px;
  left: calc(50% - 5px);
  border-width: 0 2px 2px 0;
  border-style: solid;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.staff-popup:before {
  background-color: inherit;
  border-color: inherit;
  display: inline-block;
  margin: 0;
  content: '';
  width: 10px;
  height: 10px;
  position: absolute;
  top: 36px;
  left: calc(50% - 5px);
  border-width: 0 2px 2px 0;
  border-style: solid;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.icon_parent_box {
  position: relative;
  display: inline-block;
}

.icon_parent_box .tooltiptext {
  visibility: hidden;
  text-align: center;
  position: absolute;
  z-index: 1;
  top: 53%;
  left: 88px;
  margin-left: -60px;
  font-size: 12px;
}
.icon_parent_box:hover .tooltiptext {
  visibility: visible;
}


.chip_parent_box {
  position: relative;
  display: inline-block;
}

.chip_parent_box .chip_tooltiptext {
  position: absolute;
  visibility: hidden;
  z-index: 1000;
  right: -84px;
    top: -3px;
  margin-left: -60px;
  font-size: 12px;
}

.chip_parent_box:hover .chip_tooltiptext {
  visibility: visible !important;
}

.short_name {
  padding: 4px;
    font-size: 10px;
    white-space: nowrap;
    top: 5px;
    right: 8.5px;
}
</style>