
<template>
  <div class="fullCalBox relative">
     <!-- <div class="flex  flex-wrap text-text1">
        <div class="flex mb-1 flex-wrap lg:flex-nowrap w-full justify-between">
          <div class=" hidden lg:block heading-2  w-full font-bold  text-center ">
            <div class="xl:ml-72">
              <span><span v-if="!hideBtn" class="text-primary font-semibold heading-4 cursor-pointer " @click="selectedDateByPicker()">
            Go to Today
            </span></span>
            <span class=" whitespace-nowrap">{{showTiltle}}</span>
            </div>
          </div>
          <div class="text-center flex sm:justify-end flex-wrap sm:flex-nowrap gap-1 mb-1 md:mb-0">
            <div @click="changeViewCall('agendaDay')">
            <Chip :class="`${selectedView === 'timelineDay' ||  selectedView === 'agendaDay'  ? 'activeView' : '' } py-1 cursor-pointer`" style="font-size: 16px" :chipText="`1 Day`"/>
            </div>
            <div @click="changeViewCall('month')">
            <Chip :class="`${selectedView === 'month' ? 'activeView' : '' } py-1 cursor-pointer`" style="font-size: 16px" :chipText="`Month`"/>
            </div>
            <div @click="goDateCall('prev')">
            <Chip class="py-1 cursor-pointer activeView" style="font-size: 16px" :iconClass="'fa fa-chevron-left'" :chipText="``"/>
            </div>
            <div @click="goDateCall('next')">
            <Chip class="py-1 cursor-pointer activeView" style="font-size: 16px" :iconClass="'fa fa-chevron-right'" :chipText="``"/>
            </div>
          </div>
        </div>
    </div> -->
    <div class="cust_card bg-white p-4"  id="schedulerSection" v-if="isReady && resourceList.length > 0 || selectedView === 'agendaDay' || selectedView === 'timelineFourDay' || selectedView === 'month'">
        <full-calendar
        ref="calendar"
        :config="config"
        :events="events"
        @event-render="eventRender"
        @event-resize="sendReminderPopup($event, 'resize')"
        @event-drop="sendReminderPopup($event, 'drop')"
        @day-click="dayCLick"
        @event-selected="eventSelected"
        />
    </div>
    
    <div class="popup_overlay px-4" v-if="createJobPopup">
      <div class="custom_dialog rounded-xl" style="width: 400px; overflow: auto; max-height: 90%;">
        <div class="relative bg-white p-2 text-center">
          <div class=" font-medium text-black heading-3 p-4">
            {{newJobObj.dateTitle}}
          </div>
          <div class="divider"></div>
          <div class="hover:bg-blue-50 p-3 cursor-pointer" @click="createTask()">
            <span class=" font-semibold text-primary heading-5">Create new Task</span>
          </div>
          <div class="dividerLight"></div>
          <div class="hover:bg-blue-50 p-3 cursor-pointer" @click="createNewJob()">
            <span class=" font-semibold text-primary heading-5">Create new Job</span>
          </div>
          <div class="dividerLight"></div>
          <div class="hover:bg-blue-50 p-3 cursor-pointer" @click="existingJob()">
            <span class=" font-semibold text-primary heading-5">Add to existing Job</span>
          </div>
          <div class="dividerLight"></div>
          <div class="hover:bg-blue-50 p-3 cursor-pointer" @click="cancelPopup()">
            <span class=" font-semibold text-primary heading-5">Cancel</span>
          </div>
        </div>
      </div>
    </div>
    <div class="popup_overlay px-4" v-if="selectedDatePicker">
      <div class="custom_dialog rounded-xl" >
        <DateTimepicker v-click-outside="closeDatePicker"
                v-model="currentViewDate"
                :title="''"
                :showOnlyDate="true"
                :hideGoToday="true"
                :hideBtn="true"
                :roundedCard="true"
                @input="updateDate()"
                >
            </DateTimepicker>
      </div>
    </div>
    <SearchJob v-if="searchJobPopup" />
    <ScheduleExistingJobAdd v-if="openAddSchedulePage" :detail="selectedExisitngObject" :startTime="this.newJobObj.selectedStartTime" :endTime="this.newJobObj.selectedEndTime" />
  </div>
</template>

<script>
// import Vue from 'vue'
import {FilterPermissions} from '@/utils/Permissions.js'
import {getDisplayText} from '@/utils/convertDateAndTime.js'
import ScheduleExistingJobAdd from '@/View/schedule/components/scheduleExistingJobAdd.vue'
import SearchJob from '@/View/schedule/components/searchJob.vue'
import 'fullcalendar/dist/fullcalendar.min.css'
import {FullCalendar} from 'vue-full-calendar'
import 'fullcalendar-scheduler'
// import 'fullcalendar-scheduler/dist/scheduler.min.css'
// import Chip from '../../components/chip.vue';
import MyJobApp from "@/api/MyJobApp.js";
import {ReFormatDate} from '@/utils/validation'
import moment from 'moment'
import DateTimepicker from '../../components/dateTimepicker.vue'
// Vue.use(FullCalendar)
export default {
    components: {
        FullCalendar,
        // Chip,
        SearchJob,
        ScheduleExistingJobAdd,
        DateTimepicker
    },
  data() {
    return {
      isReady: false,
      titleDate: moment(new Date()).format('YYYY-MM-DD'),
      activeUer: 0,
      resourceList: [],
      mainEventList: [],
      scheduleList: [],
      employeeList: [],
      userTimeFilter: 'time',
      selectedDatePicker: false,
      currentViewDate: null,
      hideBtn: true,
      selectedExisitngObject: {},
      openAddSchedulePage: false,
      searchJobPopup: false,
      createJobPopup: false,
      sendEmailPopup: false,
      eventOperation: '',
      selectedView: 'agendaDay',
      dropEvents: {},
      showTodayButton: false,
      schedulerDisplayDate: '',
      rangeStartDateTime: '',
      rangeEndDateTime: '',
      showTiltle:'',
      scheduleStartDt: '',
      selectedDate: null,
      view: '',
      scheduleEndDt: '',
      newJobObj: {
        dateTitle: '',
        selectedStartTime: '',
        selectedEndTime: ''
      },
      config: {
        // minTime: this.$store.getters.schedulerStartTime,
        // maxTime: this.$store.getters.schedulerEndTime,
        selectable:true,
        schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
        defaultView: 'agendaDay',
        // defaultDate: '',
        resourceLabelText: 'Team',
        height: 'auto',
        views: {
          oneDay: {
            buttonText: 'Day',
            slotDuration: '00:15',
            slotWidth: '25'
          },
          timelineFourDay: {
            buttonText: '3 Days',
            type: 'agenda',
            slotDuration: '00:15',
            duration: { days: 3 }
          },
          agendaDay: {
            buttonText: 'Day',
            slotDuration: '00:15',
            slotWidth: '25'
          }
        },
        viewRender: this.changeView,
      },
      events: [],
      
    }
  },
  props: ['teamListData', 'scheduleData', 'defaultView', 'startTime', 'titleDvalue'],
  created () {
    // console.log('emailVerify>>>>>>>>>>>>>>> d efaultView >>>>>>>>>>>>>>>>>>>>>>>>>>>>>', this.defaultView)
    // console.log('emailVerify>>>>>>>>>>>>>>> teamListData >>>>>>>>>>>>>>>>>>>>>>>>>>>>>', this.teamListData)
    // console.log('emailVerify>>>>>>>>>>>>>>> scheduleData >>>>>>>>>>>>>>>>>>>>>>>>>>>>>', this.scheduleData)
    // console.log('emailVerify>>>>>>>>>>>>>>> startTime >>>>>>>>>>>>>>>>>>>>>>>>>>>>>', this.startTime)
    // console.log('>>>>>>>>>>>>>>>  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>', this.$route.query)
    this.activeUer = JSON.parse(localStorage.getItem('jobUserInfo')).userDetailId
    // if (this.defaultView === 'timelineDay') {
    //   this.userTimeFilter = 'time'
    // }
    this.selectedView = this.defaultView
    this.config.defaultView = "month"
    // if (this.$route.query.subView !== undefined) {
    //   if (this.$route.query.subView !== '') {
    //     this.userTimeFilter = this.$route.query.subView
    //   }
    // }


    if (this.defaultView === 'timelineDay' && this.teamListData !== null) {
      let resourceArr = []
      this.teamListData.map(a=> {
        resourceArr.push({id: a.userDetailId, title: a.firstName, children: [], cellNumber: ''})
      })
      this.resourceList = resourceArr
      // this.config = {...this.config, resources: resourceArr};
      setTimeout(() => {
        this.isReady = true
      }, 0);
    }

          if (this.$route.query.stime !== undefined) {
      if (this.$route.query.stime !== '') {
        


        const startTimestamp = new Date(this.$route.query.stime).getTime();
        const endTimestamp = new Date(this.$route.query.etime).getTime();

  // Calculate the average timestamp
  const averageTimestamp = (startTimestamp + endTimestamp) / 2;
  // Convert the average timestamp back to a date object
  const centerDate = new Date(averageTimestamp);
      this.config.defaultDate = moment(new Date(centerDate)).format('YYYY-MM-DD')


  const year = centerDate.getFullYear();
  const month = centerDate.getMonth();

  // Create a new date object for the first day of the month
  const firstDateOfMonth = new Date(year, month, 1);
  let dateByPicker = moment(firstDateOfMonth).format('YYYY-MM-DD')
  this.$refs.calendar.fireMethod('gotoDate', dateByPicker)
      }
    }
    
  },
  mounted () {
    // console.log('==============================================', this.config);
    document.title = 'Schedule'
    // this.config.defaultView = this.defaultView
    // this.selectedView = this.defaultView

    // if (this.defaultView === 'timelineDay' && this.teamListData !== null) {
    //   let resourceArr = []
    //       this.teamListData.map(a=> {
    //         resourceArr.push({id: a.userDetailId, title: a.firstName, children: [], cellNumber: ''})
    //       })
    //       this.resourceList = resourceArr
    //       this.config = {...this.config, resources: resourceArr};
    //     }
    if (this.$route.query.stime !== undefined && this.$route.query.etime !== undefined) {
      this.scheduleStartDt = new Date(this.$route.query.stime)
      this.scheduleEndDt = new Date(this.$route.query.etime)
      
    } else {
      this.scheduleStartDt = new Date().setDate(new Date().getDate() - 7)
      this.scheduleEndDt = new Date()
    }
    
    if (this.selectedView === 'agendaDay') {
      this.getTimeViewData()
    }
    this.$root.$on("closeScheduleJobSel", (response, isClose) => {
      if (!isClose) {
        this.selectedExisitngObject = response
        this.openAddSchedulePage = true
      }
      this.searchJobPopup = false
    });
    this.$root.$on('addExistingJobHandler', (response) => {
      if (response) {
        this.$root.$emit('refershJobList')
      }
      this.openAddSchedulePage = false
    })
    this.$root.$on('eventHandelFromParent', (eventType) => {
     if (eventType === 'selectedDateByPicker') {
       this.selectedDateByPicker()
     }
     if (eventType === 'prev') {
       this.goDateCall('prev')
     }
     if (eventType === 'next') {
       this.goDateCall('next')
     }
    })
  
  },
  watch: {
    scheduleData: {
      handler () {
        // console.log("selectedViewselectedViewselectedViewselectedViewselectedViewselectedViewselectedViewselectedViewselectedView", this.scheduleData);
        this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
        let  data = this.scheduleData
        this.mainEventList = data
        this.scheduleList = data
        
        if (this.scheduleData.length > 0) {
            this.configureCalenarEvents()
          }
      },
      deep: true
    },
    showTiltle: {
      handler () {
        this.$emit('titleHandler', this.showTiltle, moment(this.scheduleStartDt).format('YYYY-MM-DD'), moment(this.scheduleEndDt).format('YYYY-MM-DD'))
      },
      deep: true
    },
    selectedDate: {
      handler () {
        var startDt = new Date(ReFormatDate(this.selectedDate) + ' 00:00')
        this.scheduleStartDt = startDt.setDate(startDt.getDate() - 7)
        var endDt = new Date(ReFormatDate(this.selectedDate) + ' 00:00')
        this.scheduleEndDt = endDt.setDate(endDt.getDate() + 50)
      },
      deep: true
    },
    teamListData: {
      handler () {
        // console.log('checl ca;; 111111111111', this.teamListData);
        if (this.defaultView === 'timelineDay' && this.resourceArr.length === 0) {
          let resourceArr = []
          this.teamListData.map(a=> {
            resourceArr.push({id: a.userDetailId, title: a.firstName, children: [], cellNumber: ''})
          })
          this.resourceList = resourceArr
          // this.config = {...this.config, resources: resourceArr};
          setTimeout(() => {
            this.isReady = true
          }, 0);
        }
      },
      deep: true
    },
    defaultView: {
      handler () {
        this.selectedView = this.defaultView
      },
      deep: true
    }
  },
  methods: {
    getTimeViewData () {
      // this.rangeStartDateTime = new Date(moment(this.$route.query.start).format(this.$store.getters.dateTimeFormate))
      // this.rangeEndDateTime = new Date(moment(this.$route.query.end).format(this.$store.getters.dateTimeFormate))
      setTimeout(() => {
        this.changeViewCall('agendaDay')
      }, 1000);
    },
    createNewJob () {
      let temp = FilterPermissions('job')
      if (temp) {
        this.$router.push({name: 'scheduleAddJob', query: {startTime: JSON.stringify(this.newJobObj.selectedStartTime), endTime: JSON.stringify(this.newJobObj.selectedEndTime)}})
      } else {
        this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to create Job`})
      }
    },
    createTask () {
      let temp = FilterPermissions('task')
      if (temp) {
        this.$router.push({name: 'AddNewTask', params: {taskId: 0}, query: {startTime: JSON.stringify(this.newJobObj.selectedStartTime), endTime: JSON.stringify(this.newJobObj.selectedEndTime)}})
      } else {
        this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to create Task`})
      }
    },
    existingJob () {
      let temp = FilterPermissions('job')
      if (temp) {
        this.createJobPopup = false
        this.searchJobPopup = true
      } else {
        this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to create Job`})
      }
    },
    cancelPopup () {
      this.createJobPopup = false
      this.newJobObj.selectedEndTime = ''
      this.newJobObj.selectedStartTime = ''
      this.newJobObj.dateTitle = ''
    },
    closeDatePicker () {
      this.selectedDatePicker = false
    },
    updateDate () {
      if (this.currentViewDate !== '') {
        this.selectedDatePicker = false
        this.selectedDateByPicker(this.currentViewDate)
      }
    },
    selectedDateByPicker (val) {      
      if (moment(new Date()).format('YYYY-MM-DD') === moment(val).format('YYYY-MM-DD') ) {
        this.hideBtn = true
      } else {
        this.hideBtn = false
      }

      this.titleDate = moment(val).format('YYYY-MM-DD')

      if (val) {
        let dateByPicker = moment(val).format('YYYY-MM-DD')
        this.$refs.calendar.fireMethod('gotoDate', dateByPicker)
      } else {
        let dateByPicker = moment(new Date()).format('YYYY-MM-DD')
        this.$refs.calendar.fireMethod('gotoDate', dateByPicker)
      }
    },
    eventSelected (event) {
      // console.log("event1", event.which);
      // console.log("event12", event.button);
      let startDt = moment(event.start).format(this.$store.getters.dateTimeFormate)
      if (event.obj.isrequest && event.obj.entityType === 'request') {
        let temp = FilterPermissions('request')
        if (temp) {
          this.$router.push({name: 'requestDetail', params: {requestId: event.obj.jobId}})
        } else {
          this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to open Request detail`})
        }
        } else if (!event.obj.isrequest && event.obj.entityType === 'job') {
          let temp = FilterPermissions('job')
          if (temp) {
            this.$router.push({name: 'visitDetail', params: {visitId: event.obj.visitId}})
          } else {
            this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to open Visit detail`})
          }
        } else if (!event.obj.isrequest && event.obj.entityType === 'task') {
        let temp = FilterPermissions('task')
        if (temp) {
          this.$router.push({name: 'TaskDetail', params: {taskId: event.obj.taskId}, query: {date: startDt}})
        } else {
          this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to open Task detail`})
        }
      }
    },
    goDateCall (date) {
      let schdulelmntdate = this.$refs.calendar
      schdulelmntdate.fireMethod(date)
    },
    changeViewCall (view) {
      if (view !== 'month' ) {
        this.$router.push({name: 'schedule'})
      }
    },
    changeView (view) {
      // console.log("check ", view);
      this.selectedView = view.name
      this.$store.dispatch('SetLoader', {status: true, message: 'Creating shedule view...'})
      this.headerDate = view.title
      let lowerBound = moment(this.scheduleStartDt).format('YYYY-MM-DD')
      let upperBound = moment(this.scheduleEndDt).format('YYYY-MM-DD')
      this.currentViewDate = moment(view.title).format('YYYY-MM-DD')
      if (moment(new Date()).format('YYYY-MM-DD') === moment(view.title).format('YYYY-MM-DD') ) {
        this.hideBtn = true
      } else {
        this.hideBtn = false
      }
      if (view.name === 'timelineDay' || view.name === 'agendaDay') {
        this.showTiltle = '(' + view.dateProfile.date.format('ddd') + ') ' + view.dateProfile.date.format('MMM, DD')
        var compareDate = view.dateProfile.date.format('YYYY-MM-DD')
      } else if (view.name === 'month') {
        this.showTodayButton = false
        this.showTiltle = view.title
        compareDate = view.dateProfile.date.format('YYYY-MM-DD')
        this.selectedDate = moment(view.start).format('YYYY-MM-DD')
      } else {
        this.showTiltle = '(' + moment(view.start).format('ddd') + ') ' + moment(view.start).format('MMM, DD') + ' - ' + '(' + moment(view.end).format('ddd') + ') ' + moment(view.end).format('MMM, DD')
        compareDate = moment(view.start).format('YYYY-MM-DD')
      }
      if (lowerBound > compareDate) {
        this.selectedDate = compareDate
      }
      if (upperBound < compareDate) {
        this.selectedDate = compareDate
      }
      this.titleDate = compareDate
      this.view = view
      this.rangeStartDateTime = new Date(moment(view.start).format(this.$store.getters.dateTimeFormate))
      this.rangeEndDateTime = new Date(moment(view.end).format(this.$store.getters.dateTimeFormate))
      this.showMarker = false
      var scheduleDate = new Date(view.start)
      scheduleDate = new Date(scheduleDate.setHours('00', '00', '00')).toString()
      this.schedulerDisplayDate = scheduleDate.toString()
      var currentDate = new Date()
      currentDate.setHours('00', '00', '00')
      currentDate = currentDate.toString()
      if (currentDate === scheduleDate) {
        this.showTodayButton = false
      } else {
        this.showTodayButton = true
      }
      this.$store.dispatch('SetLoader', {status: false, message: ''})
      let schedulerSectionHeight= document.getElementById('schedulerSection').clientHeight;
      // this.$router.push({query: {view: view.name}})
      this.$root.$emit('render-map', {rangeStartDateTime: this.rangeStartDateTime, rangeEndDateTime: this.rangeEndDateTime, name: this.selectedView, schedulerSectionHeight: schedulerSectionHeight, subView: this.userTimeFilter, titleDate: this.titleDate})
    },
    dayCLick (date, jsEvent, view) {
      // console.log('_________________++++++++++++++++++++++++++++++++', new Date());
      // console.log('_________________++++++++++++++++++++++++++++++++', date);
      var tzDifference = date._d.getTimezoneOffset() // this gives me timezone difference of local and UTC time in minutes
      var offsetTime = new Date(date._d.getTime() + tzDifference * 60 * 1000)
      // console.log("offsetTime", offsetTime);
      let sheduleDate =  moment(new Date(offsetTime)).format('YYYY-MM-DD HH:mm')
      let tempStartDate = null
      let tempEndDate = null
      let d1 = new Date (sheduleDate)
      let d2 = new Date ( d1 )
      if (view.type !== 'month') {
        if (view.type === 'agendaWeek' || view.type === 'timelineDay' || view.type === 'agendaDay') {
          d2.setMinutes ( d1.getMinutes() + 30 );
          tempStartDate = d1
          tempEndDate = d2
        } else if (view.type === 'timelineFourDay' || view.type === 'agendaSevenDays') {
          d2.setMinutes ( d1.getMinutes() + 15 );
          tempStartDate = d1
          tempEndDate = d2
        }
        else {
          d2.setMinutes ( d1.getMinutes() + 15 );
          tempStartDate = d1
          tempEndDate = d2
        }
      //  console.log("111111111111111111111111", tempStartDate);
      // console.log('2222222222222222222222222', tempEndDate);
        this.newJobObj.selectedStartTime = tempStartDate
        this.newJobObj.selectedEndTime = tempEndDate
        this.newJobObj.dateTitle = getDisplayText(this.newJobObj.selectedStartTime, this.newJobObj.selectedEndTime)
        this.createJobPopup = true
      }
    },
    sendReminderPopup (event, opration) {
      this.dropEvents = event
      this.eventOperation = opration
      // console.log('check event', event);
      // console.log('check opration', opration);
      if (opration === 'drop') {
        if (event.obj.runningTimer > 0) {
          this.configureCalenarEvents()
          this.$store.dispatch('SetAlert', {showAlert: true, message: 'Cannot change time of running job', color: 'error'})
          return false
        } else if (event.obj.entityType === 'task') {
          this.configureCalenarEvents()
          return false
        }
        this.eventResize(false)
      } else {
        if (event.obj.runningTimer > 0) {
          this.configureCalenarEvents()
          this.$store.dispatch('SetAlert', {showAlert: true, message: 'Cannot change time of running job', color: 'error'})
          return false
        } else if (event.obj.entityType === 'task') {
          this.configureCalenarEvents()
          return false
        }
        this.eventResize(false)
      }
    },
    eventResize () {
      // console.log('this.dropEvents.obj', this.dropEvents.obj)
      let endDt = moment(this.dropEvents.end).format(this.$store.getters.dateTimeFormate)
      // let endDisplayDt = moment(this.dropEvents.end).format(this.$store.getters.dateTimeFormate)
      endDt = new Date(endDt).toUTCString()
      let startDt = moment(this.dropEvents.start).format(this.$store.getters.dateTimeFormate)
      // let startDisplayDt = moment(this.dropEvents.start).format(this.$store.getters.dateTimeFormate)
      startDt = new Date(startDt).toUTCString()
      this.dropEvents.obj.scheduledStartdateTime = startDt
      this.dropEvents.obj.scheduledEnddateTime = endDt
      if (!this.dropEvents.obj.isrequest && this.dropEvents.obj.entityType === 'job') {
        let visitId = parseInt(this.dropEvents.obj.visitId)
        this.$store.dispatch('SetLoader', {status: true, message: 'Updating shedule info...'})
        MyJobApp.updateVisitTime(
            {
              visitId: visitId,
              visitStartDateTime: startDt,
              visitEndDateTime: endDt,
              oldAssignee: this.dropEvents.obj.userDetailId,
              newAssignee: parseInt(this.dropEvents.resourceId),
            },
            response => {
            this.$root.$emit('refershJobList')
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            },
            () => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            }
        )
      } else if (this.dropEvents.obj.isrequest && this.dropEvents.obj.entityType === 'request') {
        let visitId = parseInt(this.dropEvents.obj.jobId)
        this.$store.dispatch('SetLoader', {status: true, message: 'Updating shedule info...'})
        MyJobApp.RequestDragDropEvent(
          {
            requestId: visitId,
            startTime: startDt,
            endTime: endDt,
            oldAssignee: this.dropEvents.obj.userDetailId,
            newAssignee: parseInt(this.dropEvents.resourceId),
          },
          response => {
            this.$root.$emit('refershJobList')
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          },
          () => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
      } else if (this.dropEvents.obj.entityType === 'task') {
        return false
      } 
    },
    configureCalenarEvents () {
      // console.log('this.selectedView', this.selectedView);
      this.events = []
      if (this.selectedView === 'agendaDay') {
        // console.log('==============================', this.scheduleList);
        for (let pIndex = 0; pIndex < this.scheduleList.length; pIndex++) {
          const parentObj = this.scheduleList[pIndex]
          for (let cIndex = 0; cIndex < parentObj.length; cIndex++) {
            const childObject = parentObj[cIndex]
            if (childObject.entityType === 'task') {
              let sDate = new Date(childObject.startTime).toUTCString()
              sDate = moment(sDate).format('YYYY-MM-DD HH:mm')
              let eDate = new Date(childObject.endTime).toUTCString()
              eDate = moment(eDate).format('YYYY-MM-DD HH:mm')
              let mainObject = {
                resourceId: 0,
                title: '',
                start: '',
                end: '',
                color: '',
                obj: {},
                id: 0,
                type: '',
                jobId: 0,
              }
              mainObject.id = childObject.taskId
              mainObject.jobId = childObject.taskId
              mainObject.type =  childObject.entityType
              mainObject.title = childObject.taskTitle
              mainObject.resourceId = childObject.userDetailId ? childObject.userDetailId : 0
              mainObject.color = childObject.isTaskCompleted ? '#8e8e8e' : '#055c58'
              mainObject.start = sDate
              mainObject.end = eDate
              mainObject.obj =  childObject
              this.events.push(mainObject)
            } else if (childObject.entityType === 'job' || childObject.entityType === 'request' ) {
              let sDate = new Date(childObject.start).toUTCString()
              sDate = moment(sDate).format('YYYY-MM-DD HH:mm')
              let eDate = new Date(childObject.end).toUTCString()
              eDate = moment(eDate).format('YYYY-MM-DD HH:mm')
              let mainObject = {
                resourceId: 0,
                title: '',
                start: '',
                end: '',
                color: '',
                obj: {},
                id: 0,
                type: '',
                jobId: 0,
              }
              if (childObject.isrequest) {
                mainObject.id = childObject.jobId
                mainObject.jobId = childObject.visitId
              } else {
                mainObject.id = childObject.visitId
                mainObject.jobId = childObject.jobId
              }
              mainObject.resourceId = childObject.userDetailId ? childObject.userDetailId : 0
              mainObject.title = childObject.isrequest ? 'Request for: ' + childObject.customerName : childObject.title  + ' (' + 'job#' + childObject.jobId + ')'
              mainObject.color = childObject.runningTimer > 0 ? '#F16C1F' : childObject.isCompleted ? 'green' : childObject.isrequest ? '#b0856d' : '#1295BA'
              mainObject.start = sDate
              mainObject.end = eDate
              mainObject.obj =  childObject
              mainObject.type =  childObject.entityType
              this.events.push(mainObject)
            }
          }
        }
        // console.log("++++++++++++++++++++++++++++++++++", this.events);
      } else {
        // for (let pIndex = 0; pIndex < this.scheduleList.length; pIndex++) {
          // const parentObj = this.scheduleList[pIndex]
          // console.log('parentObj', parentObj)
          for (let cIndex = 0; cIndex < this.scheduleList.length; cIndex++) {
            const childObject = this.scheduleList[cIndex]
            if (childObject.entityType === 'task') {
              // console.log('childObject11', childObject)
              let sDate = new Date(childObject.startTime).toUTCString()
              sDate = moment(sDate).format('YYYY-MM-DD HH:mm')
              let eDate = new Date(childObject.endTime).toUTCString()
              eDate = moment(eDate).format('YYYY-MM-DD HH:mm')
              let mainObject = {
                resourceId: 0,
                title: '',
                start: '',
                end: '',
                color: '',
                obj: {}
              }
              mainObject.title =  'Task: ' + childObject.taskId + ' ' + childObject.taskTitle
              if (this.userTimeFilter === 'time') {
                mainObject.resourceId = 1
              } else {
                mainObject.resourceId = childObject.userDetailId ? childObject.userDetailId : 0
              }
              mainObject.color = childObject.isTaskCompleted ? '#8e8e8e' : '#055c58'
              mainObject.start = sDate
              mainObject.end = eDate
              mainObject.obj =  childObject
              this.events.push(mainObject)
            } else if (childObject.entityType === 'job' || childObject.entityType === 'request') {
              let sDate = new Date(childObject.start).toUTCString()
              sDate = moment(sDate).format('YYYY-MM-DD HH:mm')
              let eDate = new Date(childObject.end).toUTCString()
              eDate = moment(eDate).format('YYYY-MM-DD HH:mm')
              let mainObject = {
                resourceId: 0,
                title: '', 
                start: '',
                end: '',
                color: '',
                obj: {}
              }
              if (this.userTimeFilter === 'time') {
                mainObject.resourceId = 1
              } else {
                mainObject.resourceId = childObject.userDetailId ? childObject.userDetailId : 0
              }
              mainObject.title = childObject.isrequest ? 'Request for: ' + childObject.customerName : childObject.title  + ' (' + 'job#' + childObject.jobId + ')'
              mainObject.color = childObject.runningTimer > 0 ? '#F16C1F' : childObject.isCompleted ? 'green' : childObject.isrequest ? '#b0856d' : '#1295BA'
              mainObject.start = sDate
              mainObject.end = eDate
              mainObject.obj =  childObject
              this.events.push(mainObject)
            }
          }
        // }
      }
      this.$store.dispatch("SetLoader", { status: false, message: "" });
    },
    eventRender (event, element) {
      let job = event.obj
      if (job.entityType == "job" || job.entityType == "request" ) {

        let color = job.runningTimer > 0 ? '#F16C1F' : job.isCompleted ? 'green' : job.isrequest ? '#b0856d' : '#1295BA'


        let div = '<div id="hideEvent" style="margin-left:2px;background-color: ' + color + ';' + ';font-size:14px;overflow:hidden">' 

        if (!job.isrequest) {
         div += 'Visit'  + job.jobVisitNumber + '/' + job.totalJobVisit + " "
        }

        if (this.userTimeFilter !== 'user' || this.selectedView !== 'agendaDay') {
          div += job.assignToList 
        }
        
        div += ' </div>'
        element.append(div)
      } else {
        let color = job.isTaskCompleted ? '#8e8e8e' : '#055c58'
        let div = '<div id="hideEvent" style="margin-left:2px;color:white;background-color: ' + color + ';' +  ';font-size:14px;overflow:hidden">'
        
        if (this.userTimeFilter !== 'user' || this.selectedView !== 'agendaDay') {
          div +=  job.assignToList
        }

        div += ' </div>'
        element.append(div)
      }
      
    },
    timeFilter (fill) {
      
      this.userTimeFilter = fill
      this.$store.dispatch('SetLoader', {status: false, message: ''})
      let schedulerSectionHeight= document.getElementById('schedulerSection').clientHeight;
      this.$root.$emit('timeWiseHandler', {rangeStartDateTime: this.rangeStartDateTime, rangeEndDateTime: this.rangeEndDateTime, name: 'agendaDay', schedulerSectionHeight: schedulerSectionHeight, titleDate: this.titleDate})
        
    },
    usarsFilter (fill) {
        this.userTimeFilter = fill
        this.$store.dispatch('SetLoader', {status: false, message: ''})
        let schedulerSectionHeight= document.getElementById('schedulerSection').clientHeight;
        this.$root.$emit('timeWiseHandler', {rangeStartDateTime: this.rangeStartDateTime, rangeEndDateTime: this.rangeEndDateTime, name: 'timelineDay', schedulerSectionHeight: schedulerSectionHeight, titleDate: this.titleDate})
    }
  },
  beforeDestroy() {
    this.$root.$off("addExistingJobHandler");
    this.$root.$off("closeScheduleJobSel");
    this.$root.$off("eventHandelFromParent");
  },
};
</script>

<style>
.fc-center {
  display: none !important;
}
.fc-scroller-canvas {
  width: 100% !important;
  min-width: 100% !important;
  width: auto;
  min-width: min-content !important;
}
.fc-header-toolbar  {
  display: none !important;
}
</style>