<template>
<div :class="`mb-1 flex items-center justify-center w-fit heading-6 whitespace-nowrap border border-gray2 rounded text-${textsColor} px-2 py-1  mr-1`">
 <span class="" v-if="iconClass !== ''">
  <i :class="`${iconClass}`"></i>
 </span>
  {{chipText}}</div>
</template>
<script>
export default {
    components: {
    },
    props: ['chipText', 'iconClass', 'textColor'],
  data() {
    return {
      textsColor: 'text1'
    };
  },
  watch: {},
  mounted() {
    if (this.textColor && this.textColor !== '') {
      this.textsColor = this.textColor
    }
  },
  methods: {},
};
</script>
<style scoped>
</style>
